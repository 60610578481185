import { Modal } from "react-bootstrap";
import { TrashIcon } from "../icons/TrashIcon";

const DeleteFileModal = ({ show, onHide, handleDeleteFile,btnLoading }) => {
  return (
    <>
      <Modal
        show={show}
        className="uploadModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4>Delete PDF File</h4>
          <p className="px-5 px-md-0">
            Are you sure that you want to delete this file. Confirm your action.
          </p>

          <div className="d-flex align-items-center mt-3 justify-content-center">
            <button className="btnGray" onClick={onHide}>
              Cancel
            </button>
            <button
              className="btnDanger"
              onClick={() => { !btnLoading &&
                handleDeleteFile();
                setTimeout(() => {
                  onHide();
                }, 1000);
              }}
            >
              {" "}
              <span className="me-1">
                <TrashIcon />
              </span>
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteFileModal;

import { Image } from "react-bootstrap";
import Logo from "../../assets/images/whiteLogo.png";
import Social1 from "../../assets/images/social1.png";
import Social2 from "../../assets/images/social2.png";
import Social3 from "../../assets/images/social3.png";
import { LeftArrow } from "../../compoments/icons/LeftArrow";
import { LeftArrowSlide } from "../../compoments/icons/LeftArrowSlide";
import { Link } from "react-router-dom";


// Reusable ArrowButton component
const ArrowButton = ({ direction, onClick }) => (
  <div className={`arrowButton pointer ${direction}`} onClick={onClick}>
    <LeftArrowSlide direction={direction} />
  </div>
);

// Reusable Footer component
const Footer = ({ activeStep, setActiveStep }) => (
  <div className="footerTxt text-center">
    <div className="mb-4 pb-3">
      <div className="slidersDots">
        <span className={activeStep ===1 && "activeDot"} onClick={()=>setActiveStep(1)}></span>
        <span className={activeStep ===2 && "activeDot"} onClick={()=>setActiveStep(2)}></span>
        <span className={activeStep ===3 && "activeDot"} onClick={()=>setActiveStep(3)}></span>
        <span className={activeStep ===4 && "activeDot"} onClick={()=>setActiveStep(4)}></span>
        <span className={activeStep ===5 && "activeDot"} onClick={()=>setActiveStep(5)}></span>
      </div>
    </div>


    <div className="copyRight">
      <div>SIGNED SEALED SOLD LIMITED - Company number: 15812033</div>
      <div>Registered on Companies House.</div>
    </div>
  </div>
);

// Reusable SkipLogin component
const SkipLogin = ({setActiveStep}) => (
  <div className="skipLogin">
    <div onClick={()=>setActiveStep(5)}>
      <span style={{ textDecoration: 'underline' }}>Skip to Login</span>{" "}
      <span className="ms-2">
        <LeftArrow />
      </span>
    </div>
  </div>
);

const Step1 = ({ activeStep, setActiveStep }) => (
  <section className="splashScreen">
    <div className="splashBG">
      <div className="logo">
        <Image src={Logo} alt="Company Logo" width={450} />
      </div>
      <div className="leftArrowSplash">
        <ArrowButton
          direction="left"
          onClick={() => setActiveStep(activeStep + 1)}
        />
      </div>
      <div className="socialBtn">
        <Link to='https://www.instagram.com/signedsealedsold_/'><img src={Social1} width={40} height={40} alt="Instagram" /></Link>
        <Link to='https://www.linkedin.com/company/signed-sealed-sold/' target="_blank" > <img src={Social2} width={40} height={40} alt="Linkedin" /></Link>
       <Link to='https://www.youtube.com/@SignedSealedSold' target="_blank" > <img src={Social3} width={40} height={40} alt="Youtube" /></Link>
      </div>
      <Footer activeStep={activeStep} setActiveStep={setActiveStep} />
      <SkipLogin setActiveStep={setActiveStep} />
    </div>
  </section>
);

export default Step1;

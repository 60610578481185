import SplashScreen from "../../splash";
import SellerSidebar from "./sidebar/seller";

function PageLayout({ children }) {
  return (
    <>
      
        <main className="content">{children}</main>
      
    </>
  );
}

export default PageLayout;

export const ArrowDown = ({direction, color}) => {
  return (
    <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.72125 10.0315L7.06792 5.68481C7.58125 5.17147 8.42125 5.17147 8.93458 5.68481L13.2813 10.0315"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  );
};

export const TickIcon = ({ direction, color }) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0013 3.16699C8.65464 3.16699 2.66797 9.15366 2.66797 16.5003C2.66797 23.847 8.65464 29.8337 16.0013 29.8337C23.348 29.8337 29.3346 23.847 29.3346 16.5003C29.3346 9.15366 23.348 3.16699 16.0013 3.16699ZM22.3746 13.4337L14.8146 20.9937C14.628 21.1803 14.3746 21.287 14.108 21.287C13.8413 21.287 13.588 21.1803 13.4013 20.9937L9.62797 17.2203C9.2413 16.8337 9.2413 16.1937 9.62797 15.807C10.0146 15.4203 10.6546 15.4203 11.0413 15.807L14.108 18.8737L20.9613 12.0203C21.348 11.6337 21.988 11.6337 22.3746 12.0203C22.7613 12.407 22.7613 13.0337 22.3746 13.4337Z"
        fill="#51CC57"
      />
    </svg>
  );
};

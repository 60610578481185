import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ allowedRoles }) => {
  const { userData } = useSelector(
    (state) => state.authReducer
  );
  if (!userData) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles && !allowedRoles.includes(userData.role)) {
    // Redirect to unauthorized page if role is not allowed
    return <Navigate to="/404" replace />;
  }

  // If authenticated and role is allowed, render the route
  return <Outlet />;
};

export default PrivateRoute;

import PageLayout from "../compoments/layout/layout";
import HeaderDashboard from "../compoments/Header";
import { useEffect, useState } from "react";
import AgentSidebar from "../compoments/layout/sidebar/agent";
import { Col, Row } from "react-bootstrap";
import { EyeIcon } from "../compoments/icons/EyeIcon";
import { useSelector } from "react-redux";
import axios from "axios";
import { BlueDoc } from "../compoments/icons/BlueDoc";
import { useParams } from "react-router-dom";
import PreviewFileModal from "../compoments/modals/PreviewFileModal";
import UploadFileModal from "../compoments/modals/UploadFileModal";
import { toast } from "react-toastify";
import { GreenTick } from "../compoments/icons/GreenTick";
import Loader from "../compoments/LoaderBtn";

const AddressDocList = () => {
  const { id } = useParams();
  const { token, selectedAddressID } = useSelector(
    (state) => state.authReducer
  );

  const [previewModal, setPreviewModal] = useState(false);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [title, setTitle] = useState("");

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/doc/all-doc/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.documents) {
        setDocuments(response.data.documents);
      }
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch documents");
      console.error("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDocuments();
    }
  }, [id]);

  const handleDownload = async (fileUrl, fileName) => {
    if (!fileUrl) {
      console.error("File URL is undefined.");
      return;
    }
    setBtnLoading(true);
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const aTag = document.createElement("a");
      aTag.href = url;
      aTag.setAttribute("download", fileName);
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);

      // Revoke the object URL after the download
      window.URL.revokeObjectURL(url);
      setPreviewModal(false)
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      console.error("Error downloading the file:", error);
    }
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [docId, setDocId] = useState("");

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter(
      (file) => file.type !== "application/pdf"
    );

    if (invalidFiles.length > 0) {
      setError("Please upload only PDF files.");
      e.target.value = ""; // Clear the input field
    } else {
      setError(null);
      setSelectedFiles(files);
      if (files.length > 0) {
        setUploadModal(true);
      }
    }
  };

  const [btnLoading, setBtnLoading] = useState(false);
  const handleUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("doc", selectedFiles[i]);
    }

    formData.append("addressId", id);
    formData.append("docId", docId);
    setBtnLoading(true);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/doc/update-doc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.message);
      setBtnLoading(false);
      setUploadModal(false);
      fetchDocuments();
    } catch (error) {
      console.error("Upload Error:", error);
      setBtnLoading(false);
    }
  };

  return (
    <>
    {loading && <Loader />}
    {btnLoading && <Loader />}
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <AgentSidebar />
        </div>
        <section className="mainContent">
        <HeaderDashboard type="Agent" />

          <div className="customCard">
            <div className="pageBody mb-0">
              <h2 className="heading mb-1">Manage Property</h2>
              <p className="mb-0">
                As an estate agent, you can update property details, upload
                relevant documents, and check sale statuses.
              </p>
            </div>
          </div>

          <div className="customCard">
            <div className="pageBody mb-0">
              <h3 className="heading">Documents Required</h3>

              {loading ? (
                <p className="py-5 text-center">Loading Please Wait...</p>
              ) : (
                <Row>
                  {documents.length > 0 ? (
                    documents.map((item, index) => (
                      <Col key={index} md={3} className="mb-3">
                        <div className="documentCard">
                          <div
                            className="customCard"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setFile(item?.url);
                              setFileName(item.originalName);
                              setPreviewModal(true);
                              setTitle(item.docType);
                            }}
                          >
                            <EyeIcon />
                            <p className="mb-1 mt-2">Preview PDF File</p>
                          </div>
                          <div className="">
                            <div className="mt-2 position-relative">
                              <input
                                type="file"
                                className="uploadField"
                                accept=".pdf"
                                multiple
                                value={''}
                                onChange={handleFileChange}
                                onClick={() => setDocId(item?._id)}
                              />
                              <button className="btnUploadFile">
                                <span className="me-2">
                                  <BlueDoc />
                                </span>
                                Upload File
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="cardName mt-1 py-2 d-flex align-items-center justify-content-center">
                          <span className="me-1">
                            {item?.isEdit && <GreenTick />}
                          </span>
                          {item?.docType || "Unknown Type"}
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div className="py-5 text-center">
                      No documents available.
                    </div>
                  )}
                </Row>
              )}
            </div>
          </div>
        </section>
      </section>

      {previewModal && (
        <PreviewFileModal
          show={previewModal}
          onHide={() => setPreviewModal(false)}
          file={file}
          download={() => handleDownload(file, fileName)}
          btnLoading={btnLoading}
          title={title}
        />
      )}

      {uploadModal && (
        <UploadFileModal
          show={uploadModal}
          onHide={() => setUploadModal(false)}
          selectedFile={selectedFiles}
          handleUpload={handleUpload}
          btnLoading={btnLoading}
        />
      )}
    </PageLayout>
    </>
  );
};

export default AddressDocList;

import AppRouter from "./Route";
import "./App.scss";
import PageLayout from "./compoments/layout/layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App({ children }) {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
      />
      <AppRouter>
        <PageLayout>
          <main className="content">{children}</main>
        </PageLayout>
      </AppRouter>
    </>
  );
}

export default App;

export const VerifiedIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10.2646" r="4" fill="#0081CA"/>
</svg>

  );
};




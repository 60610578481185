// src/reducers/AuthReducer.js
const initialState = {
  userData: {},
  token: "",
  addressList: [],
  documentList: {},
  activeMenu: null,
  activeDoc: {},
  selectedAddressID: "",
  documentsList: [],
  showMenu: false,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.payload.user, // Use the data from the action's payload
        token: action.payload.token, // Update the token from the action's payload
      };
    case "USER_PROFILE":
      return {
        ...state,
        userData: action.payload.user, // Use the data from the action's payload
      };
    case "ADDRESS_LIST":
      return {
        ...state,
        addressList: action.payload, // Use the data from the action's payload
      };
    case "DOCUMENTS_LIST":
      return {
        ...state,
        documentsList: action.payload, // Use the data from the action's payload
      };
    case "DOC_LIST":
      return {
        ...state,
        documentList: action.payload, // Use the data from the action's payload
      };
    case "SELECTED_ADDRESS_ID":
      return {
        ...state,
        selectedAddressID: action.payload, // Use the data from the action's payload
      };
    case "ACTIVE_MENU":
      return {
        ...state,
        activeMenu: action.payload, // Use the data from the action's payload
      };
    case "ACTIVE_DOC":
      return {
        ...state,
        activeDoc: action.payload, // Use the data from the action's payload
      };
    case "SHOW_MENU":
      return {
        ...state,
        showMenu: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        userData: {}, // Use the data from the action's payload
        token: "", // Update the token from the action's payload
        addressList: [],
        documentList: {},
        activeMenu: null,
        activeDoc: {},
        selectedAddressID: "",
        documentsList: [],
      };

    default:
      return state;
  }
};

export default AuthReducer;

import PageLayout from "../compoments/layout/layout";
import SellerSidebar from "../compoments/layout/sidebar/seller";
import HeaderDashboard from "../compoments/Header";
import { Image } from "react-bootstrap";

import Help from "./../assets/images/Help.png";
import { useState } from "react";
import { HelpCenterIcon } from "../compoments/icons/HelpCenterIcon";
import { CopyEmail } from "../compoments/icons/CopyEmail";
import { useSelector } from "react-redux";

const HelpCenter = () => {
  const [text, setText] = useState("Info@signedsealedsold.co.uk");
  const {  userData } = useSelector((state) => state.authReducer);


  const copyTextToClipboard = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Email copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  return (
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <SellerSidebar />
        </div>
        <section className="mainContent">
        <HeaderDashboard type={
              userData?.role === "buyer"
                ? "Buyer"
                : userData?.role === "seller"
                ? "Seller"
                : userData?.role === "estateAgent"
                ? "Agent"
                : "Solicitor"
            }/>
          <div className="customCard">
            <div className="helpCenterSection">
              <div className="helpCenterHeading">
                <span>
                 <HelpCenterIcon/>
                </span>{" "}
                <h3 className="mb-0">Help Center</h3>
              </div>
              <p className="para">
                If you have any questions or need assistance, please reach out
                to us at,
              </p>
              <h6 className="emailHelp">Info@signedsealedsold.co.uk</h6>
              <div className="copyEmail" onClick={copyTextToClipboard}>
                <span>
                 <CopyEmail/>
                </span>{" "}
                <p className="copy">Copy Email Address</p>
              </div>

              <div className="py-4 my-3">
                <Image src={Help} alt="" className="mobileHelpImage" />
              </div>

              <p className="para mb-0">
                We're here to help and will respond as soon as possible!
              </p>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};

export default HelpCenter;

export const BlueDoc = () => {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.8694 5.34307C11.9721 5.45931 13.0812 5.5043 14.1895 5.47681L14.1869 5.47243L14.1863 5.47306C12.8776 3.51821 11.1879 1.84221 9.21554 0.542969C9.18647 1.64474 9.23197 2.74708 9.35077 3.84329C9.43545 4.56572 10.1375 5.26004 10.8693 5.34315L10.8694 5.34307Z" fill="#0081CA"/>
<path d="M7.3656 6.81827C7.37508 6.7214 7.34159 6.62454 7.27334 6.55392C7.2051 6.48331 7.10905 6.44518 7.01046 6.44956C6.90746 6.45393 6.81203 6.50393 6.75074 6.5858C6.68944 6.66579 6.66859 6.76952 6.6945 6.86702C6.80635 7.30134 6.96433 7.72257 7.16655 8.1244H7.16592C7.25944 7.69382 7.32642 7.2576 7.36624 6.81827L7.3656 6.81827Z" fill="#0081CA"/>
<path d="M3.74569 13.3994C3.72294 13.5619 3.81268 13.7194 3.96434 13.785C4.11664 13.8513 4.29421 13.8088 4.3991 13.6825C4.7315 13.2813 5.0405 12.862 5.32487 12.4258C4.86989 12.6295 4.40227 12.8632 3.92707 13.132C3.82723 13.1882 3.76024 13.2869 3.74569 13.3994Z" fill="#0081CA"/>
<path d="M7.43568 9.92383C7.29855 10.3013 7.14753 10.6619 6.98828 11.0031C7.39145 10.8781 7.77818 10.7781 8.14217 10.6938C7.89129 10.4513 7.65496 10.1938 7.43568 9.92383Z" fill="#0081CA"/>
<path d="M14.2203 6.35169C13.9991 6.35731 13.7799 6.36044 13.5612 6.36044L13.5618 6.36106C12.631 6.36106 11.7015 6.31169 10.7763 6.2142H10.7687C10.2177 6.15171 9.6755 5.88049 9.24326 5.4524C8.81102 5.02494 8.53677 4.48875 8.47295 3.94379V3.93629L8.47169 3.93504C8.3491 2.79827 8.30233 1.65462 8.3314 0.511689C6.59301 0.469818 4.85331 0.540437 3.1243 0.723541C1.96915 0.856027 0.861438 1.95093 0.727491 3.09324C0.42417 5.89665 0.42417 11.1006 0.727491 13.9047C0.861458 15.0471 1.96922 16.1426 3.1243 16.275C5.95029 16.575 8.80022 16.575 11.6262 16.275C12.7814 16.1425 13.8891 15.047 14.023 13.9047C14.2296 11.9943 14.2949 8.97144 14.2203 6.35169ZM11.7281 11.6237C11.6421 11.9268 11.419 12.1742 11.1246 12.2936C10.8295 12.413 10.4952 12.3911 10.219 12.2349C9.73748 11.9624 9.27746 11.6537 8.84459 11.3112H8.84396C8.04647 11.4662 7.26164 11.6818 6.49762 11.9561C6.0616 12.7336 5.55543 13.4716 4.98606 14.1596C4.78384 14.4033 4.48748 14.5508 4.16961 14.5658H4.11464C3.78857 14.5664 3.47892 14.4271 3.26407 14.1846C3.04923 13.9421 2.95127 13.6197 2.99487 13.2997C3.03974 12.9554 3.24638 12.6523 3.55222 12.481C4.32505 12.0442 5.13139 11.6661 5.96294 11.3518C6.0135 11.2574 6.06342 11.1612 6.11334 11.063L6.11271 11.0624C6.42235 10.4518 6.68143 9.81816 6.88871 9.16627C6.46784 8.51321 6.15378 7.79827 5.95789 7.04775C5.83846 6.57029 6.05837 6.07283 6.49439 5.83537C6.92978 5.59789 7.47261 5.67913 7.81763 6.03348C8.03755 6.26096 8.1475 6.57156 8.1197 6.88527C8.05208 7.61582 7.91748 8.33888 7.71653 9.0457C8.1001 9.5869 8.55065 10.0781 9.05747 10.5093C9.59334 10.4174 10.1349 10.3556 10.6778 10.325V10.3256C11.0108 10.3081 11.3318 10.4506 11.5403 10.708C11.7482 10.9655 11.8184 11.3062 11.7281 11.6237Z" fill="#0081CA"/>
<path d="M10.7211 11.0746C10.486 11.0871 10.2092 11.1095 9.89453 11.1464C10.1144 11.2958 10.3495 11.4414 10.5998 11.5839H10.5991C10.6718 11.6258 10.7596 11.6314 10.8367 11.5989C10.9176 11.5664 10.9777 11.4976 10.9991 11.4145C11.0206 11.3314 11.0017 11.2427 10.9473 11.1752C10.893 11.1077 10.8083 11.0702 10.7211 11.0746Z" fill="#0081CA"/>
</svg>
  );
};

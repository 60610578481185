import React, { useState } from "react";
import PageLayout from "../compoments/layout/layout";
import HeaderDashboard from "../compoments/Header";
import BuyerSidebar from "../compoments/layout/sidebar/buyer";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import SellerSidebar from "../compoments/layout/sidebar/seller";
import AgentSidebar from "../compoments/layout/sidebar/agent";
import SolicitorSidebar from "../compoments/layout/sidebar/solicitor";
import Loader from "../compoments/LoaderBtn";

const AccountSetting = () => {
  const { token, userData } = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState({
    currentPassword: true,
    newPassword: true,
    currentPasswordVisible: false,
    newPasswordVisible: false,
  });

  const togglePasswordVisibility = (field) => {
    setEditMode((prevState) => ({
      ...prevState,
      [field + "Visible"]: !prevState[field + "Visible"],
    }));
  };

  const handleFieldChange = (e, field) => {
    setEditMode((prevState) => ({
      ...prevState,
      [field + "Value"]: e.target.value,
    }));
  };

  const handleUpdatePassword = async () => {
    const { currentPasswordValue, newPasswordValue } = editMode;
    if (!currentPasswordValue || !newPasswordValue) {
      toast.error("Please fill in both password fields.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/user/update-password`,
        {
          currentPassword: currentPasswordValue,
          newPassword: newPasswordValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);
      setLoading(false);
      setEditMode({});
    } catch (error) {
      console.error(
        "Error updating password:",
        error.response || error.message
      );
      setLoading(false);
      toast.error("Failed to update password.");
    }
  };

  const renderProfileField = (field, label, isPassword = false) => (
    <div className="singleField my-2 d-flex align-items-center">
      <div className="flex-grow-1">
        <div className="label">{label}</div>
        <input
          type={
            isPassword && !editMode[field + "Visible"] ? "password" : "text"
          }
          autoFocus
          autoComplete={isPassword ? "new-password" : "off"}
          placeholder="Enter password"
          value={
            editMode[field + "Value"] !== undefined
              ? editMode[field + "Value"]
              : ""
          }
          onChange={(e) => handleFieldChange(e, field)}
          className="form-control border-0 px-0 py-1 h-auto"
          style={{ minWidth: "400px" }}
          name={field} // Use unique name to avoid autofill
        />
      </div>
      <div
        className="pointer ml-2 text-primary"
        onClick={() => togglePasswordVisibility(field)}
        role="button"
        aria-label={
          editMode[field + "Visible"] ? "Hide password" : "Show password"
        }
      >
        {editMode[field + "Visible"] ? "Hide" : "Show"}
      </div>
    </div>
  );

  return (
    <>
      {loading && <Loader />}
      <PageLayout>
        <section className="pageWrapper">
          <div className="sidebarContent">
            {userData?.role === "buyer" && <BuyerSidebar />}
            {userData?.role === "seller" && <SellerSidebar />}
            {userData?.role === "estateAgent" && <AgentSidebar />}
            {userData?.role === "solicitor" && <SolicitorSidebar />}
          </div>
          <section className="mainContent">
            <HeaderDashboard
              type={
                userData?.role === "buyer"
                  ? "Buyer"
                  : userData?.role === "seller"
                  ? "Seller"
                  : userData?.role === "estateAgent"
                  ? "Agent"
                  : "Solicitor"
              }
            />
            <div className="customCard">
              <div className="profileSection">
                <div className="heading">Account Settings</div>
                <div className="profileContainer">
                  <div className="formContainer">
                    {renderProfileField(
                      "currentPassword",
                      "Current Password",
                      true
                    )}
                    {renderProfileField("newPassword", "New Password", true)}
                    <div className="text-end">
                      <button
                        onClick={loading ? "" : handleUpdatePassword}
                        className="btnPrimary px-5 mt-2"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </PageLayout>
    </>
  );
};

export default AccountSetting;

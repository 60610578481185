export const ArrowDownMenu = ({ direction, color }) => {
  return (
    <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2787 5.96658L8.93208 10.3132C8.41875 10.8266 7.57875 10.8266 7.06542 10.3132L2.71875 5.96658"
      stroke="black"
      strokeOpacity="0.75"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    </svg>
  );
};

import { Dropdown, Form, Image } from "react-bootstrap";
import { SearchIcon } from "./icons/SearchIcon";
import { GreenIcon } from "./icons/GreenIcon";
import { RedIcon } from "./icons/RedIcon";
import Avatar from "./../assets/images/Avatar.png";
import { ArrowDownMenu } from "./icons/ArrowDownMenu";
import { useEffect, useState } from "react";
import LogoutModal from "./modals/LogoutModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { BlueIcon } from "./icons/BlueIcon";
import { OpenMenu, Signout } from "../redux/reducers/auth/action";

const HeaderDashboard = ({ type, page }) => {
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current route information

  const { userData, token, showMenu } = useSelector((state) => state.authReducer);

  const handleLogout = () =>{
    dispatch(Signout({navigate}));
  }
  console.log({ type });

  useEffect(() => {
    // Fetch user profile data on component mount
    const fetchUserProfile = async () => {
      if (!token) {
        toast.error("Authentication token is missing.");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/user/user-detail`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const user = response.data;
          dispatch({
            type: "USER_PROFILE",
            payload: user,
          });
        } else {
          toast.error("Failed to fetch user profile data.");
        }
      } catch (error) {
        console.error(
          "Error fetching user profile:",
          error.response || error.message
        );
        handleLogout()
        toast.error("Failed to load user profile");
      }
    };

    fetchUserProfile();
    return () => {
      // If you need to handle cleanup (e.g., cancel request, reset states)
    };
  }, [dispatch, token]);

  console.log({ type });

 // Functions to open and close the menu
const handleOpenMenu = () => {
  dispatch(OpenMenu(true)); // Open the menu
};

const handleCloseMenu = () => {
  dispatch(OpenMenu(false)); // Close the menu
};

const pathname = location.pathname
useEffect(() => {
  dispatch(OpenMenu(false));
}, [pathname, dispatch]);

  console.log({showMenu});
  
  return (
    <>
      <div className="customCard">
        <div className="pageHeader">
          <div className="position-relative d-none d-md-block">
            <Form.Control type="text" placeholder="Search" autoComplete="off" />
            <span className="fieldIcon">
              <SearchIcon />
            </span>
          </div>
          <div className="d-md-none d-block" onClick={showMenu? handleCloseMenu: handleOpenMenu }>
            <svg
              width="20"
              height="25"
              viewBox="0 0 20 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.2599 18.0043H3.94178C3.33063 18.0043 2.82812 17.6343 2.82812 17.1843C2.82812 16.7343 3.33063 16.3643 3.94178 16.3643H16.2599C16.8711 16.3643 17.3736 16.7343 17.3736 17.1843C17.3736 17.6443 16.8711 18.0043 16.2599 18.0043Z"
                fill="#292D32"
              />
              <path
                d="M16.2599 13.3636H3.94178C3.33063 13.3636 2.82812 12.9936 2.82812 12.5436C2.82812 12.0936 3.33063 11.7236 3.94178 11.7236H16.2599C16.8711 11.7236 17.3736 12.0936 17.3736 12.5436C17.3736 12.9936 16.8711 13.3636 16.2599 13.3636Z"
                fill="#292D32"
              />
              <path
                d="M16.2599 8.72398H3.94178C3.33063 8.72398 2.82812 8.35398 2.82812 7.90398C2.82812 7.45398 3.33063 7.08398 3.94178 7.08398H16.2599C16.8711 7.08398 17.3736 7.45398 17.3736 7.90398C17.3736 8.35398 16.8711 8.72398 16.2599 8.72398Z"
                fill="#292D32"
              />
            </svg>
          </div>
          <div className="d-flex align-items-center">
            {(type === "Buyer" || type === "Seller") && (
              <div className="d-none d-md-flex">
                <>
                  <button className="uploadPending">
                    <span>
                      <RedIcon />
                    </span>
                    Upload Pending
                  </button>
                  <button className="uploaded mx-2">
                    <span>
                      <GreenIcon />
                    </span>
                    Uploaded
                  </button>
                  <button className="approved mx-2">
                    <span>
                      <BlueIcon />
                    </span>
                    Approved
                  </button>
                </>
              </div>
            )}
            <Dropdown className="profileDropdown">
              <Dropdown.Toggle id="dropdown-basic" className="">
                <Image src={userData?.image || Avatar} alt="" />
                <span className="">
                  <ArrowDownMenu />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={() => navigate(`/profile`)}>
                  Profile
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => navigate(`/account-setting`)}
                >
                  Settings
                </Dropdown.Item>
                {(type === "Buyer" || type === "Seller") && (
                  <Dropdown.Item
                    href="#"
                    onClick={() => navigate(`/help-center`)}
                  >
                    Help Center{" "}
                  </Dropdown.Item>
                )}
                <Dropdown.Item href="#" onClick={() => setLogoutModal(true)}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {(type === "Buyer" || type === "Seller") && (
          <div className="d-block mt-3 d-md-none">
            <>
              <button className="uploadPending w-100">
                <span>
                  <RedIcon />
                </span>
                Upload Pending
              </button>
              <button className="uploaded w-100 my-2">
                <span>
                  <GreenIcon />
                </span>
                Uploaded
              </button>
              <button className="approved w-100">
                <span>
                  <BlueIcon />
                </span>
                Approved
              </button>
            </>
          </div>
        )}
      </div>

      {logoutModal && (
        <LogoutModal show={logoutModal} onHide={() => setLogoutModal(false)} />
      )}
    </>
  );
};

export default HeaderDashboard;

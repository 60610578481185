export const EyeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7096 7.62506C15.7846 4.60006 12.968 2.8584 10.0013 2.8584C8.51797 2.8584 7.0763 3.29173 5.75964 4.10007C4.44297 4.91673 3.25964 6.1084 2.29297 7.62506C1.45964 8.9334 1.45964 11.0584 2.29297 12.3667C4.21797 15.4001 7.03464 17.1334 10.0013 17.1334C11.4846 17.1334 12.9263 16.7001 14.243 15.8917C15.5596 15.0751 16.743 13.8834 17.7096 12.3667C18.543 11.0667 18.543 8.9334 17.7096 7.62506ZM10.0013 13.3667C8.13464 13.3667 6.63464 11.8584 6.63464 10.0001C6.63464 8.14173 8.13464 6.6334 10.0013 6.6334C11.868 6.6334 13.368 8.14173 13.368 10.0001C13.368 11.8584 11.868 13.3667 10.0013 13.3667Z"
        fill="#D39927"
      />
      <path
        d="M10 7.61621C8.69167 7.61621 7.625 8.68288 7.625 9.99954C7.625 11.3079 8.69167 12.3745 10 12.3745C11.3083 12.3745 12.3833 11.3079 12.3833 9.99954C12.3833 8.69121 11.3083 7.61621 10 7.61621Z"
        fill="#D39927"
      />
    </svg>
  );
};

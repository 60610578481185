export const UploadIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5013 1.66699C5.9013 1.66699 2.16797 5.40033 2.16797 10.0003C2.16797 14.6003 5.9013 18.3337 10.5013 18.3337C15.1013 18.3337 18.8346 14.6003 18.8346 10.0003C18.8346 5.40033 15.1013 1.66699 10.5013 1.66699ZM13.443 10.8587L10.943 13.3587C10.818 13.4837 10.6596 13.542 10.5013 13.542C10.343 13.542 10.1846 13.4837 10.0596 13.3587L7.55964 10.8587C7.31797 10.617 7.31797 10.217 7.55964 9.97533C7.8013 9.73366 8.2013 9.73366 8.44297 9.97533L9.8763 11.4087V7.08366C9.8763 6.74199 10.1596 6.45866 10.5013 6.45866C10.843 6.45866 11.1263 6.74199 11.1263 7.08366V11.4087L12.5596 9.97533C12.8013 9.73366 13.2013 9.73366 13.443 9.97533C13.6846 10.217 13.6846 10.617 13.443 10.8587Z"
        fill="white"
      />
    </svg>
  );
};

export const GreenTick = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8737 1.83398C5.20036 1.83398 2.20703 4.82732 2.20703 8.50065C2.20703 12.174 5.20036 15.1673 8.8737 15.1673C12.547 15.1673 15.5404 12.174 15.5404 8.50065C15.5404 4.82732 12.547 1.83398 8.8737 1.83398ZM12.0604 6.96732L8.28036 10.7473C8.18703 10.8407 8.06036 10.894 7.92703 10.894C7.7937 10.894 7.66703 10.8407 7.5737 10.7473L5.68703 8.86065C5.4937 8.66732 5.4937 8.34732 5.68703 8.15398C5.88036 7.96065 6.20036 7.96065 6.3937 8.15398L7.92703 9.68732L11.3537 6.26065C11.547 6.06732 11.867 6.06732 12.0604 6.26065C12.2537 6.45398 12.2537 6.76732 12.0604 6.96732Z"
        fill="#51CC57"
      />
    </svg>
  );
};

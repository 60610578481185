import { Modal } from "react-bootstrap";
import { TickIcon } from "../icons/TickIcon";

const DownloadFileModal = ({ show, onHide }) => {
  return (
    <>
      <Modal
        show={show}
        className="uploadModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4>
            <span className="me-2">
              <TickIcon />
            </span>
            File Downloaded!
          </h4>
          <p className="px-5 px-md-0">
            The PropertyTitleDeeds.pdf file has been successfully downloaded.
          </p>

          <div className=" mt-3 ">
            <button className="btnGray w-100" onClick={onHide}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DownloadFileModal;

export const LeftArrowSlide = ({direction, color}) => {
  return (
    <svg
    width="50"
    height="50"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="88" height="88" rx="44" fill="white" fillOpacity={color == 'black'? "1" :"0.4"} />
    <path
      d={
        direction === "left"
          ? "M48.0508 33.8838L58.1674 44.0005L48.0508 54.1171"
          : "M39.9492 33.8838L29.8326 44.0005L39.9492 54.1171"
      }
      stroke={color == 'black'? 'black': "white"}
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d={direction === "left" ? "M29.832 44H57.882" : "M58.168 44H30.118"}
      stroke={color == 'black'? 'black': "white"}      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  );
};

import { Image } from "react-bootstrap";
import Logo from "../../assets/images/BlackLogo.svg";
import { LeftArrow } from "../../compoments/icons/LeftArrow";
import { LeftArrowSlide } from "../../compoments/icons/LeftArrowSlide";
import Footer from "./FooterStep";

// Reusable ArrowButton component
const ArrowButton = ({ direction, onClick }) => (
  <div className={`arrowButton ${direction}`} onClick={onClick}>
    <LeftArrowSlide direction={direction} color={'black'} />
  </div>
);



// Reusable SkipLogin component
const SkipLogin = ({setActiveStep}) => (
  <div className="skipLogin">
    <div onClick={()=>setActiveStep(5)}>
      <span style={{ textDecoration: "underline" }}>Skip to Login</span>{" "}
      <span className="ms-2">
        <LeftArrow />
      </span>
    </div>
  </div>
);

const Step2 = ({ activeStep, setActiveStep }) => (
  <div className="step2Splash">
    <section className="stepHow">
      <div className="leftArrow">
        <ArrowButton
          direction="right"
          onClick={() => setActiveStep(activeStep - 1)}
        />
      </div>
      <div
        style={{
          height: "auto",
          width: 700,
          background: "#fff",
          borderRadius: 32,
          border: "1px solid var(--cardBorder)",
        }}
        className="p-md-5 p-3 text-center mobileSlider"
      >
        <h2 className="mt-4" style={{ fontWeight: 700 }}>
          Who Are We?
        </h2>
        <p>
          SignedSealedSold was created to help streamline the property sale
          process and alleviate stress for all: Estate Agents, Conveyancers,
          Buyers, and Vendors.
        </p>
        <p>
          Selling a house can be a lengthy and tedious process, and you can
          often face a few hurdles along the way. SignedSealedSold is here to
          ensure a smooth sale from start to finish.
        </p>
        <p>
          With our online portal, you can access everything you need in one
          location – upload documents straight from your smartphone, view and
          request documents, check the status of the sale, and rely on our
          communication hub for any queries you might have. This is all at your
          fingertips at any time, in any place.
        </p>
        <p>
          Say goodbye to countless phone calls and emails to chase for updates.
          We are on-hand to monitor the sale process and act as the ‘middleman’.
        </p>
        <div className="my-4 pt-md-4">
          <Image src={Logo} alt="Company Logo" width={80} />
        </div>
      </div>
      <div className="rightArrow">
        <ArrowButton
          direction="left"
          onClick={() => setActiveStep(activeStep + 1)}
        />
      </div>
    </section>
    <Footer activeStep={activeStep} setActiveStep={setActiveStep}/>
    <SkipLogin setActiveStep={setActiveStep} />
  </div>
);

export default Step2;

export const HelpCenterIcon = () => {
  return (
    <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.668 3.24023H9.33464C5.33464 3.24023 2.66797 5.9069 2.66797 9.9069V17.9069C2.66797 21.9069 5.33464 24.5736 9.33464 24.5736V27.4136C9.33464 28.4802 10.5213 29.1202 11.4013 28.5202L17.3346 24.5736H22.668C26.668 24.5736 29.3346 21.9069 29.3346 17.9069V9.9069C29.3346 5.9069 26.668 3.24023 22.668 3.24023ZM16.0013 19.4669C15.4413 19.4669 15.0013 19.0136 15.0013 18.4669C15.0013 17.9202 15.4413 17.4669 16.0013 17.4669C16.5613 17.4669 17.0013 17.9202 17.0013 18.4669C17.0013 19.0136 16.5613 19.4669 16.0013 19.4669ZM17.6813 13.9336C17.1613 14.2802 17.0013 14.5069 17.0013 14.8802V15.1602C17.0013 15.7069 16.548 16.1602 16.0013 16.1602C15.4546 16.1602 15.0013 15.7069 15.0013 15.1602V14.8802C15.0013 13.3336 16.1346 12.5736 16.5613 12.2802C17.0546 11.9469 17.2146 11.7202 17.2146 11.3736C17.2146 10.7069 16.668 10.1602 16.0013 10.1602C15.3346 10.1602 14.788 10.7069 14.788 11.3736C14.788 11.9202 14.3346 12.3736 13.788 12.3736C13.2413 12.3736 12.788 11.9202 12.788 11.3736C12.788 9.60023 14.228 8.16023 16.0013 8.16023C17.7746 8.16023 19.2146 9.60023 19.2146 11.3736C19.2146 12.8936 18.0946 13.6536 17.6813 13.9336Z"
      fill="black"
    />
  </svg>
  );
};

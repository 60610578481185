import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import PageLayout from "./../compoments/layout/layout";
import HeaderDashboard from "./../compoments/Header";
import Avatar from "./../assets/images/profile.png";
import { EditIcon } from "./../compoments/icons/EditIcon";
import BuyerSidebar from "../compoments/layout/sidebar/buyer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import SellerSidebar from "../compoments/layout/sidebar/seller";
import AgentSidebar from "../compoments/layout/sidebar/agent";
import SolicitorSidebar from "../compoments/layout/sidebar/solicitor";
import Loader from "../compoments/LoaderBtn";

const Profile = () => {
  const { userData, token } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(Avatar);
  const [image, setImage] = useState(null); // Change initial state to null
  const [editMode, setEditMode] = useState({});
  const [loading, setLoading] = useState(false);
  

  console.log({ imagePreview, editMode });

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!token) {
        toast.error("Authentication token is missing.");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/user/user-detail`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const user = response.data;
          dispatch({
            type: "USER_PROFILE",
            payload: user,
          });
          setEditMode({
            nameValue: user.name,
            emailValue: user.email,
          });
        } else {
          toast.error("Failed to fetch user profile data.");
        }
      } catch (error) {
        console.error(
          "Error fetching user profile:",
          error.response || error.message
        );
        toast.error("Failed to load user profile");
      }
    };

    fetchUserProfile();
  }, [dispatch, token]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const previewURL = URL.createObjectURL(file);

      // Update image preview and set the image file for uploading
      setImagePreview(previewURL);
      setImage(file);

      // Cleanup the object URL to avoid memory leaks
      return () => {
        URL.revokeObjectURL(previewURL);
      };
    }
  };

  const toggleEditMode = (field) => {
    setEditMode((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleFieldChange = (e, field) => {
    setEditMode((prevState) => ({
      ...prevState,
      [field + "Value"]: e.target.value,
    }));
  };

  const handleUpdateProfile = async () => {
    const formData = new FormData();

    if (editMode.nameValue) {
      formData.append("name", editMode.nameValue);
    }

    if (image) {
      formData.append("image", image); // Append the image file only if it exists
    }
    setLoading(true)
    try {
      const response = await axios.patch(
          `${process.env.REACT_APP_API_BASE_URL}/user/update-info`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the correct token
          },
        }
      );

      toast.success(response.data.message);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error("Error updating profile:", error.response || error.message);
    }
  };

  const renderProfileImage = () => (
    <div className="imageContainer">
      <Image
        src={ image? imagePreview : userData?.image || imagePreview } // Display the imagePreview or default Avatar
        alt="Profile Avatar"
        className="imagePreview"
      />
      <div className="editImage">
        <input
          type="file"
          className="fileUploadProfile"
          onChange={handleImageUpload}
          accept="image/*"
        />
        <EditIcon /> Edit
      </div>
    </div>
  );

  const renderProfileField = (field, label, value) => (
    <div className="singleField my-2">
      <div>
        <div className="label">{label}</div>
        {editMode[field] ? (
          <input
            type="text"
            autoFocus={true}
            value={
              editMode[field + "Value"] !== undefined
                ? editMode[field + "Value"]
                : value
            }
            onChange={(e) => handleFieldChange(e, field)}
            className="form-control border-0 px-1 py-0 h-auto"
            style={{ minWidth: "400px" }}
          />
        ) : (
          <div className="value">
            {editMode[field + "Value"] !== undefined
              ? editMode[field + "Value"]
              : value}
          </div>
        )}
      </div>
      {field !== "email" && (
        <div className="pointer" onClick={() => toggleEditMode(field)}>
          <EditIcon />
        </div>
      )}
    </div>
  );

  return (
    <>
    {loading && <Loader /> }
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          {userData?.role === "buyer" && <BuyerSidebar />}
          {userData?.role === "seller" && <SellerSidebar />}
          {userData?.role === "estateAgent" && <AgentSidebar />}
          {userData?.role === "solicitor" && <SolicitorSidebar />}
        </div>
        <section className="mainContent">
          <HeaderDashboard
            type={
              userData?.role === "buyer"
                ? "Buyer"
                : userData?.role === "seller"
                ? "Seller"
                : userData?.role === "estateAgent"
                ? "Agent"
                : "Solicitor"
            }
          />
          <div className="customCard">
            <div className="profileSection">
              <div className="heading">Profile Settings</div>
              <div className="profileContainer">
                {renderProfileImage()}
                <div className="formContainer ms-md-3 mt-4 mt-md-0">
                  {renderProfileField("name", "Profile Name", userData?.name)}
                  {renderProfileField(
                    "email",
                    "Email Address",
                    userData?.email
                  )}
                  <div className="text-end">
                    <button
                      onClick={handleUpdateProfile}
                      className="btnPrimary px-5 mt-2"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
    </>
  );
};

export default Profile;

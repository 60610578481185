export const LogoutIcon = () => {
  return (
    <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.66504H11.8333C9.16667 1.66504 7.5 3.33171 7.5 5.99837V9.37337H12.7083C13.05 9.37337 13.3333 9.65671 13.3333 9.99837C13.3333 10.34 13.05 10.6234 12.7083 10.6234H7.5V13.9984C7.5 16.665 9.16667 18.3317 11.8333 18.3317H13.9917C16.6583 18.3317 18.325 16.665 18.325 13.9984V5.99837C18.3333 3.33171 16.6667 1.66504 14 1.66504Z"
      fill="black"
      fillOpacity="0.75"
    />
    <path
      d="M3.79922 9.37259L5.52422 7.64759C5.64922 7.52259 5.70755 7.36426 5.70755 7.20592C5.70755 7.04759 5.64922 6.88092 5.52422 6.76426C5.28255 6.52259 4.88255 6.52259 4.64089 6.76426L1.84922 9.55592C1.60755 9.79759 1.60755 10.1976 1.84922 10.4393L4.64089 13.2309C4.88255 13.4726 5.28255 13.4726 5.52422 13.2309C5.76589 12.9893 5.76589 12.5893 5.52422 12.3476L3.79922 10.6226H7.49922V9.37259H3.79922Z"
      fill="black"
      fillOpacity="0.75"
    />
  </svg>
  );
};

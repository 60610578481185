import { useState } from "react";
import Step1 from "./partials/Step1";
import Step2 from "./partials/Step2";
import Step3 from "./partials/Step3";
import Step4 from "./partials/Step4";
import Step5 from "./partials/Step5";
import Step6 from "./partials/Step6";

const steps = [
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6
];

const SplashScreen = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [userRole, setUserRole] = useState('seller');
  const StepComponent = steps[activeStep - 1];

  return (
    <>
      <StepComponent activeStep={activeStep} setActiveStep={setActiveStep} userRole={userRole} setUserRole={setUserRole} />
    </>
  );
};

export default SplashScreen;

import { Modal } from "react-bootstrap";
import { UploadIcon } from "../icons/UploadIcon";

const UploadFileModal = ({ show, onHide, selectedFile,handleUpload , btnLoading}) => {
 

  return (
    <>
      <Modal
        show={show}
        className="uploadModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4>Upload PDF File</h4>

          <p>
            You have selected "
            <span style={{ color: "#000" }}>
              {`${selectedFile?.map((file) => file.name).join(", ")}`}"
            </span>{" "}
            file(s) from your computer. Confirm your upload.
          </p>

          <div className="d-flex align-items-center mt-3 justify-content-center">
            <button className="btnGray" onClick={onHide}>
              Cancel
            </button>
            <button className="btnPrimary" onClick={handleUpload}>
              {" "}
              <span className="me-1">
                <UploadIcon />
              </span>
              Upload
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadFileModal;

export const TrashIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5574 4.35866C16.2157 4.22533 14.8741 4.12533 13.5241 4.05033V4.04199L13.3407 2.95866C13.2157 2.19199 13.0324 1.04199 11.0824 1.04199H8.89907C6.95741 1.04199 6.77407 2.14199 6.64074 2.95033L6.46574 4.01699C5.69074 4.06699 4.91574 4.11699 4.14074 4.19199L2.44074 4.35866C2.09074 4.39199 1.84074 4.70033 1.87407 5.04199C1.90741 5.38366 2.20741 5.63366 2.55741 5.60033L4.25741 5.43366C8.62407 5.00033 13.0241 5.16699 17.4407 5.60866C17.4657 5.60866 17.4824 5.60866 17.5074 5.60866C17.8241 5.60866 18.0991 5.36699 18.1324 5.04199C18.1574 4.70033 17.9074 4.39199 17.5574 4.35866Z"
        fill="white"
      />
      <path
        d="M16.0264 6.78301C15.8264 6.57467 15.5514 6.45801 15.2681 6.45801H4.73475C4.45142 6.45801 4.16809 6.57467 3.97642 6.78301C3.78475 6.99134 3.67642 7.27467 3.69309 7.56634L4.20975 16.1163C4.30142 17.383 4.41809 18.9663 7.32642 18.9663H12.6764C15.5848 18.9663 15.7014 17.3913 15.7931 16.1163L16.3098 7.57467C16.3264 7.27467 16.2181 6.99134 16.0264 6.78301ZM11.3848 14.7913H8.60975C8.26809 14.7913 7.98475 14.508 7.98475 14.1663C7.98475 13.8247 8.26809 13.5413 8.60975 13.5413H11.3848C11.7264 13.5413 12.0098 13.8247 12.0098 14.1663C12.0098 14.508 11.7264 14.7913 11.3848 14.7913ZM12.0848 11.458H7.91809C7.57642 11.458 7.29309 11.1747 7.29309 10.833C7.29309 10.4913 7.57642 10.208 7.91809 10.208H12.0848C12.4264 10.208 12.7098 10.4913 12.7098 10.833C12.7098 11.1747 12.4264 11.458 12.0848 11.458Z"
        fill="white"
      />
    </svg>
  );
};

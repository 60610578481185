export const HomeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33203 12.915C7.99036 12.915 7.70703 13.1984 7.70703 13.54V14.79C7.70703 15.1317 7.99036 15.415 8.33203 15.415C8.6737 15.415 8.95703 15.1317 8.95703 14.79V13.54C8.95703 13.1984 8.6737 12.915 8.33203 12.915Z"
        fill="white"
      />
      <path
        d="M18.3346 17.7063H17.5013V8.31465C17.5013 7.79798 17.268 7.31465 16.8596 6.99798L11.0263 2.46465C10.4263 1.98965 9.5763 1.98965 8.9763 2.46465L3.14297 6.99798C2.73464 7.31465 2.5013 7.79798 2.5013 8.30632L2.45964 17.7063H1.66797C1.3263 17.7063 1.04297 17.9813 1.04297 18.3313C1.04297 18.673 1.3263 18.9563 1.66797 18.9563H18.3346C18.6763 18.9563 18.9596 18.673 18.9596 18.3313C18.9596 17.9813 18.6763 17.7063 18.3346 17.7063ZM8.7513 5.62298H11.2513C11.593 5.62298 11.8763 5.90632 11.8763 6.24798C11.8763 6.58965 11.593 6.87298 11.2513 6.87298H8.7513C8.40964 6.87298 8.1263 6.58965 8.1263 6.24798C8.1263 5.90632 8.40964 5.62298 8.7513 5.62298ZM14.168 17.7063H5.83464V10.4146C5.83464 9.72298 6.39297 9.16465 7.08464 9.16465H12.918C13.6096 9.16465 14.168 9.72298 14.168 10.4146V17.7063Z"
        fill="white"
      />
    </svg>
  );
};

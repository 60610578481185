export const DocIcon = () => {
  return (
    <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1677 1.84011C12.826 1.49845 12.2344 1.73178 12.2344 2.20678V5.11511C12.2344 6.33178 13.2677 7.34011 14.526 7.34011C15.3177 7.34845 16.4177 7.34845 17.3594 7.34845C17.8344 7.34845 18.0844 6.79011 17.751 6.45678C16.551 5.24845 14.401 3.07345 13.1677 1.84011Z"
                      fill="black"
                    />
                    <path
                      d="M17.082 8.48906H14.6737C12.6987 8.48906 11.0904 6.88073 11.0904 4.90573V2.4974C11.0904 2.03906 10.7154 1.66406 10.257 1.66406H6.7237C4.15703 1.66406 2.08203 3.33073 2.08203 6.30573V13.6891C2.08203 16.6641 4.15703 18.3307 6.7237 18.3307H13.2737C15.8404 18.3307 17.9154 16.6641 17.9154 13.6891V9.3224C17.9154 8.86406 17.5404 8.48906 17.082 8.48906ZM9.58203 14.7891H6.2487C5.90703 14.7891 5.6237 14.5057 5.6237 14.1641C5.6237 13.8224 5.90703 13.5391 6.2487 13.5391H9.58203C9.9237 13.5391 10.207 13.8224 10.207 14.1641C10.207 14.5057 9.9237 14.7891 9.58203 14.7891ZM11.2487 11.4557H6.2487C5.90703 11.4557 5.6237 11.1724 5.6237 10.8307C5.6237 10.4891 5.90703 10.2057 6.2487 10.2057H11.2487C11.5904 10.2057 11.8737 10.4891 11.8737 10.8307C11.8737 11.1724 11.5904 11.4557 11.2487 11.4557Z"
                      fill="black"
                    />
                  </svg>
  );
};

// src/pages/NotFound.jsx

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const NotFound = () => {
    const { userData } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();

  const roleRoutes = {
    seller: "/seller",
    solicitor: "/solicitor",
    estateAgent: "/estateAgent",
    buyer: "/buyer",
  };

  useEffect(() => {
    if (userData && userData.role) {
      const route = roleRoutes[userData.role];
      if (route) {
        navigate(route);
      } else {
        navigate("/404");
      }
    }
  }, [userData, navigate]);
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>404 - Page Not Found</h1>
      <p style={styles.text}>Sorry, the page you're looking for doesn't exist.</p>
      <Link to="/" style={styles.link}>
        Go back to Home
      </Link>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  header: {
    fontSize: '48px',
    color: '#333',
  },
  text: {
    fontSize: '18px',
    marginBottom: '20px',
  },
  link: {
    fontSize: '20px',
    textDecoration: 'none',
    color: '#007bff',
  },
};

export default NotFound;

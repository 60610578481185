export const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 18.333H2.5C2.15833 18.333 1.875 18.0497 1.875 17.708C1.875 17.3663 2.15833 17.083 2.5 17.083H17.5C17.8417 17.083 18.125 17.3663 18.125 17.708C18.125 18.0497 17.8417 18.333 17.5 18.333Z"
        fill="#0081CA"
      />
      <path
        d="M15.8495 2.90005C14.2328 1.28338 12.6495 1.24172 10.9912 2.90005L9.98283 3.90838C9.89949 3.99172 9.86616 4.12505 9.89949 4.24172C10.5328 6.45005 12.2995 8.21672 14.5078 8.85005C14.5412 8.85838 14.5745 8.86672 14.6078 8.86672C14.6995 8.86672 14.7828 8.83338 14.8495 8.76672L15.8495 7.75838C16.6745 6.94172 17.0745 6.15005 17.0745 5.35005C17.0828 4.52505 16.6828 3.72505 15.8495 2.90005Z"
        fill="#0081CA"
      />
      <path
        d="M13.007 9.60866C12.7653 9.49199 12.532 9.37533 12.307 9.24199C12.1236 9.13366 11.9486 9.01699 11.7736 8.89199C11.632 8.80033 11.4653 8.66699 11.307 8.53366C11.2903 8.52533 11.232 8.47533 11.1653 8.40866C10.8903 8.17533 10.582 7.87533 10.307 7.54199C10.282 7.52533 10.2403 7.46699 10.182 7.39199C10.0986 7.29199 9.95697 7.12532 9.83197 6.93366C9.73197 6.80866 9.6153 6.62533 9.50697 6.44199C9.37364 6.21699 9.25697 5.99199 9.1403 5.75866C8.98734 5.43088 8.55713 5.3335 8.30135 5.58928L3.6153 10.2753C3.50697 10.3837 3.40697 10.592 3.38197 10.7337L2.93197 13.9253C2.84864 14.492 3.00697 15.0253 3.35697 15.3837C3.65697 15.6753 4.07364 15.8337 4.52364 15.8337C4.62364 15.8337 4.72364 15.8253 4.82364 15.8087L8.02364 15.3587C8.17364 15.3337 8.38197 15.2337 8.48197 15.1253L13.1758 10.4315C13.4264 10.1809 13.3322 9.7496 13.007 9.60866Z"
        fill="#0081CA"
      />
    </svg>
  );
};

export const ProfileIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0013 1.66504C7.81797 1.66504 6.04297 3.44004 6.04297 5.62337C6.04297 7.76504 7.71797 9.49837 9.9013 9.57337C9.96797 9.56504 10.0346 9.56504 10.0846 9.57337C10.1013 9.57337 10.1096 9.57337 10.1263 9.57337C10.1346 9.57337 10.1346 9.57337 10.143 9.57337C12.2763 9.49837 13.9513 7.76504 13.9596 5.62337C13.9596 3.44004 12.1846 1.66504 10.0013 1.66504Z"
        fill="black"
        fillOpacity="0.75"
      />
      <path
        d="M14.2328 11.7895C11.9078 10.2395 8.11615 10.2395 5.77448 11.7895C4.71615 12.4978 4.13281 13.4561 4.13281 14.4811C4.13281 15.5061 4.71615 16.4561 5.76615 17.1561C6.93281 17.9395 8.46615 18.3311 9.99948 18.3311C11.5328 18.3311 13.0661 17.9395 14.2328 17.1561C15.2828 16.4478 15.8661 15.4978 15.8661 14.4645C15.8578 13.4395 15.2828 12.4895 14.2328 11.7895Z"
        fill="black"
        fillOpacity="0.75"
      />
    </svg>
  );
};

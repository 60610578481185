export const CopyEmail = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3346 10.7503V14.2503C13.3346 17.167 12.168 18.3337 9.2513 18.3337H5.7513C2.83464 18.3337 1.66797 17.167 1.66797 14.2503V10.7503C1.66797 7.83366 2.83464 6.66699 5.7513 6.66699H9.2513C12.168 6.66699 13.3346 7.83366 13.3346 10.7503Z"
        fill="#51CC57"
      />
      <path
        d="M14.2508 1.66699H10.7508C8.24733 1.66699 7.03919 2.53264 6.74673 4.61607C6.66931 5.16758 7.13077 5.62533 7.68769 5.62533H9.25078C12.7508 5.62533 14.3758 7.25033 14.3758 10.7503V12.3134C14.3758 12.8703 14.8335 13.3318 15.385 13.2544C17.4685 12.9619 18.3341 11.7538 18.3341 9.25033V5.75033C18.3341 2.83366 17.1674 1.66699 14.2508 1.66699Z"
        fill="#51CC57"
      />
    </svg>
  );
};

export const HelpIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.168 2.02344H5.83464C3.33464 2.02344 1.66797 3.6901 1.66797 6.1901V11.1901C1.66797 13.6901 3.33464 15.3568 5.83464 15.3568V17.1318C5.83464 17.7984 6.5763 18.1984 7.1263 17.8234L10.8346 15.3568H14.168C16.668 15.3568 18.3346 13.6901 18.3346 11.1901V6.1901C18.3346 3.6901 16.668 2.02344 14.168 2.02344ZM10.0013 12.1651C9.6513 12.1651 9.3763 11.8818 9.3763 11.5401C9.3763 11.1984 9.6513 10.9151 10.0013 10.9151C10.3513 10.9151 10.6263 11.1984 10.6263 11.5401C10.6263 11.8818 10.3513 12.1651 10.0013 12.1651ZM11.0513 8.70677C10.7263 8.92344 10.6263 9.0651 10.6263 9.29844V9.47344C10.6263 9.8151 10.343 10.0984 10.0013 10.0984C9.65964 10.0984 9.3763 9.8151 9.3763 9.47344V9.29844C9.3763 8.33177 10.0846 7.85677 10.3513 7.67344C10.6596 7.4651 10.7596 7.32344 10.7596 7.10677C10.7596 6.6901 10.418 6.34844 10.0013 6.34844C9.58464 6.34844 9.24297 6.6901 9.24297 7.10677C9.24297 7.44844 8.95964 7.73177 8.61797 7.73177C8.2763 7.73177 7.99297 7.44844 7.99297 7.10677C7.99297 5.99844 8.89297 5.09844 10.0013 5.09844C11.1096 5.09844 12.0096 5.99844 12.0096 7.10677C12.0096 8.05677 11.3096 8.53177 11.0513 8.70677Z"
        fill="black"
        fillOpacity="0.75"
      />
    </svg>
  );
};

export const Logout = ({ direction, color }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 1.66699H12.3333C9.66667 1.66699 8 3.33366 8 6.00033V9.37533H13.2083C13.55 9.37533 13.8333 9.65866 13.8333 10.0003C13.8333 10.342 13.55 10.6253 13.2083 10.6253H8V14.0003C8 16.667 9.66667 18.3337 12.3333 18.3337H14.4917C17.1583 18.3337 18.825 16.667 18.825 14.0003V6.00033C18.8333 3.33366 17.1667 1.66699 14.5 1.66699Z"
        fill="white"
      />
      <path
        d="M4.29922 9.37454L6.02422 7.64954C6.14922 7.52454 6.20755 7.36621 6.20755 7.20788C6.20755 7.04954 6.14922 6.88288 6.02422 6.76621C5.78255 6.52454 5.38255 6.52454 5.14089 6.76621L2.34922 9.55788C2.10755 9.79954 2.10755 10.1995 2.34922 10.4412L5.14089 13.2329C5.38255 13.4745 5.78255 13.4745 6.02422 13.2329C6.26589 12.9912 6.26589 12.5912 6.02422 12.3495L4.29922 10.6245H7.99922V9.37454H4.29922Z"
        fill="white"
      />
    </svg>
  );
};

import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <div
      className='d-flex align-items-center justify-content-center w-100 h-100'
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.55)',
        zIndex: 9999,
      }}
    >
      <Spinner animation='border' role='status' />
    </div>
  );
};

export default Loader;

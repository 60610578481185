import { Modal } from "react-bootstrap";
import { UploadIcon } from "../icons/UploadIcon";

const PreviewFileModal = ({ show, onHide, file,download, title, btnLoading }) => {
  return (
    <>
      <Modal
        show={show}
        className="previewModal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4>{title}</h4>
          <p className="previewTxt">Preview PDF File</p>

          <div className="customCard border">
            <iframe style={{ width: "100%", height: "calc(100vh - 400px)", border: "none" }} src={`${file}#toolbar=0`} title={file} frameborder="0"></iframe>
          </div>

          <div className="d-flex align-items-center mt-3 justify-content-center">
            <button className="btnGray" onClick={onHide}>
              Cancel
            </button>
            <button className="btnPrimary" onClick={download}>
              {" "}
              <span className="me-1">
                <UploadIcon />
              </span>
             Download
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreviewFileModal;

import { useState, useEffect } from "react";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_ENDPOINTS = {
  GET_DOCUMENT_LIST: `${API_BASE_URL}/doc/get-document/list`,
  DELETE_SINGLE_DOCUMENT: `${API_BASE_URL}/doc/single-doc`,
};





const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener); // Listen for changes

    return () => media.removeEventListener("change", listener); // Cleanup listener on unmount
  }, [matches, query]);

  return matches; // Return whether the query matches or not
};

export default useMediaQuery;

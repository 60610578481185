export const DownloadIcon = ({ direction, color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0013 1.66699C5.4013 1.66699 1.66797 5.40033 1.66797 10.0003C1.66797 14.6003 5.4013 18.3337 10.0013 18.3337C14.6013 18.3337 18.3346 14.6003 18.3346 10.0003C18.3346 5.40033 14.6013 1.66699 10.0013 1.66699ZM12.943 10.8587L10.443 13.3587C10.318 13.4837 10.1596 13.542 10.0013 13.542C9.84297 13.542 9.68464 13.4837 9.55964 13.3587L7.05964 10.8587C6.81797 10.617 6.81797 10.217 7.05964 9.97533C7.3013 9.73366 7.7013 9.73366 7.94297 9.97533L9.3763 11.4087V7.08366C9.3763 6.74199 9.65964 6.45866 10.0013 6.45866C10.343 6.45866 10.6263 6.74199 10.6263 7.08366V11.4087L12.0596 9.97533C12.3013 9.73366 12.7013 9.73366 12.943 9.97533C13.1846 10.217 13.1846 10.617 12.943 10.8587Z"
        fill="#0081CA"
      />
    </svg>
  );
};

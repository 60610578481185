// Reusable Footer component
const Footer = ({ activeStep, setActiveStep }) => {
  return (
    <div className="footerTxt text-center">
      <div className="">
        <div className="slidersDots">
        <span className={activeStep ===1 && "activeDot"} onClick={()=>setActiveStep(1)}></span>
        <span className={activeStep ===2 && "activeDot"} onClick={()=>setActiveStep(2)}></span>
        <span className={activeStep ===3 && "activeDot"} onClick={()=>setActiveStep(3)}></span>
        <span className={activeStep ===4 && "activeDot"} onClick={()=>setActiveStep(4)}></span>
        <span className={activeStep ===5 && "activeDot"} onClick={()=>setActiveStep(5)}></span>
        </div>
      </div>
    </div>
  );
};
export default Footer;

import { Col, Image, Row } from "react-bootstrap";
import Placeholder from "../../assets/images/placeholderImage.png";
import { LeftArrowSlide } from "../../compoments/icons/LeftArrowSlide";
import Footer from "./FooterStep";
import SkipLogin from "./SkipLogin";

// Reusable ArrowButton component
const ArrowButton = ({ direction, onClick }) => (
  <div className={`arrowButton pointer ${direction}`} onClick={onClick}>
    <LeftArrowSlide direction={direction} color="black" />
  </div>
);

const Step3 = ({ activeStep, setActiveStep }) => {
  if (activeStep !== 3) return null;

  return (
    <div className="step3Splash">
      <section className="stepHow">
        <div className="leftArrow">
          <ArrowButton
            direction="right"
            onClick={() => setActiveStep(activeStep - 1)}
          />
        </div>
        <div
          style={{
            width: 980,
            maxHeight: "700px",
            background: "#fff",
            borderRadius: 32,
            border: "1px solid var(--cardBorder)",
          }}
          className="p-md-5 p-3 mobileSlider"
        >
          <h2 className="mt-4 text-center" style={{ fontWeight: 700 }}>
            The Faces Behind The Business
          </h2>
          <Row style={{ maxHeight: "510px", overflowY: "auto" }}>
            <Col md={8}>
              <p>
                Ambitious business women, wives, best friends – SignedSealedSold
                is a family-run business led by partners, Ceri and Francesca.
              </p>
              <p>
                “Our vision is to offer stress-free selling to all. With years
                of experience in the property industry and having been through
                the process of selling property ourselves, we came to realise
                that the process is filled with more stress than necessary.”
              </p>
              <p>
                SignedSealedSold was born after the team had a negative
                experience selling a property themselves – constant chasing,
                lack of communication, various issues along the way, and no
                clarity on where their sale was up to.
              </p>
              <p>
                “We’re all busy in our lives, without the added stress and time
                wasted chasing estate agents and solicitors. We felt we were
                missing somewhere to store and access everything in one place –
                a hub that keeps everything up to date throughout the process,
                so you’re never left in the dark.”
              </p>
            </Col>
            <Col md={4}>
              <Image src={Placeholder} alt="Company Logo" />
            </Col>
            <div className="my-4">
              <h3 className="mb-3 text-center" style={{ fontWeight: 700 }}>
                Francesca - A Little About Me
              </h3>
            </div>
            <Col md={8}>
              <p>
                Ambitious business women, wives, best friends – SignedSealedSold
                is a family-run business led by partners, Ceri and Francesca.
              </p>
              <p>
                “Our vision is to offer stress-free selling to all. With years
                of experience in the property industry and having been through
                the process of selling property ourselves, we came to realise
                that the process is filled with more stress than necessary.”
              </p>
              <p>
                SignedSealedSold was born after the team had a negative
                experience selling a property themselves – constant chasing,
                lack of communication, various issues along the way, and no
                clarity on where their sale was up to.
              </p>
              <p>
                “We’re all busy in our lives, without the added stress and time
                wasted chasing estate agents and solicitors. We felt we were
                missing somewhere to store and access everything in one place –
                a hub that keeps everything up to date throughout the process,
                so you’re never left in the dark.”
              </p>
            </Col>
            <Col md={4}>
              <Image src={Placeholder} alt="Company Logo" />
            </Col>
          </Row>
        </div>
        <div className="rightArrow">
          <ArrowButton
            direction="left"
            onClick={() => setActiveStep(activeStep + 1)}
          />
        </div>
      </section>
      <Footer activeStep={activeStep} setActiveStep={setActiveStep} />
      <SkipLogin activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  );
};

export default Step3;
